<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>legal-judge-balance</title>
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="4.5"
      y1="14.25"
      x2="19.5"
      y2="14.25"
    />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="12" y1="14.25" x2="12" y2="23.25" />
    <polyline fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" points="3 20.25 6 14.25 9 20.25" />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="15 20.25 18 14.25 21 20.25"
    />
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9,20.25a3,3,0,0,1-6,0Z" />
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21,20.25a3,3,0,0,1-6,0Z" />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="0.75"
      y1="11.25"
      x2="23.25"
      y2="11.25"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="12" cy="3.75" r="3" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M16.243,11.25a4.5,4.5,0,0,0-8.486,0"
    />
  </svg>
</template>
